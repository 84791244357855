/*
 * generarVender.js
 */

import API from '@/API.js'
import HotspotsSrv from '@/services/hotspot/HotspotsSrv.js'
import FichaTipoSrv from '@/services/hotspot/FichaTipoSrv.js'
import FichasLayoutSrv from '@/services/hotspot/FichasLayoutSrv.js'
import VendedorSrv from '@/services/hotspot/VendedorSrv.js'
import FichasSrv from '@/services/hotspot/FichasSrv.js'
import LanRouterSrv from '@/services/LanRouterSrv.js'
import Layout from '@/views/layouts/main'
import { todoGetters } from "@/state/helpers";
export default {
  name: 'GenerarVender',
  components: { Layout },
  data() {
    return {
      id_tipo: sessionStorage.getItem('argusblack.generarYvender.id_tipo') !== undefined ? sessionStorage.getItem('argusblack.generarYvender.id_tipo') : null,
      id_hotspot: sessionStorage.getItem('argusblack.generarYvender.id_hotspot') !== undefined ? sessionStorage.getItem('argusblack.generarYvender.id_hotspot') : null,
      id_layout: sessionStorage.getItem('argusblack.generarYvender.id_layout') !== undefined ? sessionStorage.getItem('argusblack.generarYvender.id_layout') : null,
      id_vendedor: sessionStorage.getItem('argusblack.generarYvender.id_vendedor') !== undefined ? sessionStorage.getItem('argusblack.generarYvender.id_vendedor') : null,
      cantidad: 1,
      prefijo_pin: sessionStorage.getItem('argusblack.generarYvender.prefijo_pin') !== null ? sessionStorage.getItem('argusblack.generarYvender.prefijo_pin') : '',
      longitud_pin: sessionStorage.getItem('argusblack.generarYvender.longitud_pin') !== null ? sessionStorage.getItem('argusblack.generarYvender.longitud_pin') : 3,
      tipo_pin: sessionStorage.getItem('argusblack.generarYvender.tipo_pin') !== null ? sessionStorage.getItem('argusblack.generarYvender.tipo_pin') : 'numerico',
      precio: sessionStorage.getItem('argusblack.generarYvender.precio') !== null ? sessionStorage.getItem('argusblack.generarYvender.precio') : 0,
      con_prefijo: sessionStorage.getItem('argusblack.generarYvender.con_prefijo') !== null ? sessionStorage.getItem('argusblack.generarYvender.con_prefijo'): false,
      tipo_autenticacion: sessionStorage.getItem('argusblack.generarYvender.tipo_autenticacion') !== null ? sessionStorage.getItem('argusblack.generarYvender.tipo_autenticacion') : 1,
      longitud_clave: sessionStorage.getItem('argusblack.generarYvender.longitud_clave') !== null ? sessionStorage.getItem('argusblack.generarYvender.longitud_clave') : 3,
      tipo_clave: sessionStorage.getItem('argusblack.generarYvender.tipo_clave') !== null ? sessionStorage.getItem('argusblack.generarYvender.tipo_clave') : 'numerico',
      tipos: [],
      hotspots: [],
      layouts: [],
      ficha:{ id: null },
      lan:{},
      visible_formgenficha: true,
      visible_qr: false,
      codigo : '',
      pin:'',
      clave: '',
      conteo_generador : 0,
      bandera_spinner: false,
      href: '',
      vendedores: []
    }
  },
  computed: {
    ...todoGetters,
    usuario: function() {
      var self = this
      return self.$store.state.todo.usuario
    },
    usuarioActEsAdmin() {
      return this.usuarioActualEsAdministrador()
    },
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  watch: {
    id_tipo: function (newIdTipo, oldIdTipo) {
      var self = this
      sessionStorage.setItem('argusblack.generarYvender.id_tipo', self.id_tipo)
    },
    id_hotspot: function (newIdHotspot, oldIdHotspot) {
      var self = this
      sessionStorage.setItem('argusblack.generarYvender.id_hotspot', self.id_hotspot)
    },
    id_layout: function (newIdLayout, oldIdLayout) {
      var self = this
      sessionStorage.setItem('argusblack.generarYvender.id_layout', self.id_layout)
    },
    id_vendedor: function (newIdVendedor, oldIdVendedor) {
      var self = this
      sessionStorage.setItem('argusblack.generarYvender.id_vendedor', self.id_vendedor)
    },
    prefijo_pin: function (newPrefijoPIN, oldPrefijoPIN) {
      var self = this
      sessionStorage.setItem('argusblack.generarYvender.prefijo_pin', self.prefijo_pin)
    },
    longitud_pin: function (newLongitudPIN, oldLongitudPIN) {
      var self = this
      sessionStorage.setItem('argusblack.generarYvender.longitud_pin', self.longitud_pin)
    },
    tipo_pin: function (newTipoPIN, oldTipoPIN) {
      var self = this
      sessionStorage.setItem('argusblack.generarYvender.tipo_pin', self.tipo_pin)
    },
    longitud_clave: function (newLongitudClave, oldLongitudClave) {
      var self = this
      sessionStorage.setItem('argusblack.generarYvender.longitud_clave', self.longitud_clave)
    },
    tipo_clave: function (newTipoClave, oldTipoClave) {
      var self = this
      sessionStorage.setItem('argusblack.generarYvender.tipo_clave', self.tipo_clave)
    },
    tipo_autenticacion: function (newTipoAutenticacion, oldTipoAutenticacion) {
      var self = this
      self.obtenerTipoAutenticacion()
      sessionStorage.setItem('argusblack.generarYvender.tipo_autenticacion', self.tipo_autenticacion)
    },
    precio: function (newPrecio, oldPrecio) {
      var self = this
      sessionStorage.setItem('argusblack.generarYvender.precio', self.precio)
    },
    con_prefijo: function (newConPrefijo, oldConPrefijo) {
      var self = this
      sessionStorage.setItem('argusblack.generarYvender.con_prefijo', self.con_prefijo)
    },

    uc (estaCargado) {
      let self = this

      // Si el usuario no está cargado se evita continuar
      if(!estaCargado) return 

      if (self.usuarioActEsAdmin) 
        self.cargarVendedores()
      else 
        self.id_vendedor = self.usuario.id
    }
  },
  created: function(){
    var self = this

    if(self.con_prefijo == 'true') self.con_prefijo = true
    else self.con_prefijo = false

    self.cargarHotspots()
    self.cargarLayouts()
    self.cargarTiposFicha()

    // Carga de la última configuración
    let configuracion = window.localStorage.getItem('argusblack.hotspot.generarVender.configuracion')

    if (configuracion != undefined) {
      configuracion = JSON.parse(configuracion)
      self.id_hotspot = configuracion.id_hotspot
      self.id_tipo = configuracion.id_tipo
      self.id_layout = configuracion.id_layout
      self.longitud_ping = configuracion.longitud_pin
      self.tipo_pin = configuracion.tipo_pin
      self.tipo_autenticacion = configuracion.tipo_autenticacion
      self.precio = configuracion.precio
    }


    // Si el usuario está cargado y es administrador
    if (self.uc) {
      if (self.usuarioActEsAdmin) self.cargarVendedores()
      else self.id_vendedor = self.usuario.id
    }
  },
  methods: {
    cargarHotspots: function(){
      var self = this
      let prom = HotspotsSrv.hotspotsJSON()

      prom.then(response => {
        self.hotspots = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = "No se pudo cargar los hotspots"
        }
        iu.msg.error(mensaje)
        console.log(error)
      })

      return prom
    },

    cargarLayouts: function() {
      var self = this, params = {}
      let prom

      self.id_layout = null
      self.tipo_autenticacion = 1

      var index = self.hotspots.findIndex(function (hotspot) {
        return hotspot.id == self.id_hotspot
      })

      if(index != -1){
        params = {
          tipo_autenticacion: self.hotspots[index].tipo_autenticacion
        }
      }

      prom = FichasLayoutSrv.fichasLayoutsJSON(params)

      prom.then(response => {
        self.layouts = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = "No se pudieron cargar los layouts de fichas"
        }
        iu.msg.error(mensaje)
        console.log(error)
      })

      return prom
    },

    cargarTiposFicha: function(){
      var self = this, params = { solo_con_perfil: true }
      let prom 

      prom = FichaTipoSrv.fichasTiposJSON(params)

      prom.then(response => {
        self.tipos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = "No se pudieron cargar los tipos de fichas"
        }
        iu.msg.error(mensaje)
        console.log(error)
      })

      return prom
    },
		cerrarFichaGenerada() {
      let self = this
      self.visible_qr = false
      self.visible_formgenficha = true
		},

    cargarVendedores: function () {
      var self = this

      VendedorSrv.vendedoresJSON().then(response => {
        self.vendedores = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se cargaron los vendedores'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    generarFicha () {
      var self = this

      if( !self.verificarDatosCorrectos() ) return

      var bloques = []
      
      var nuevoBloque = {
        id_hotspot: self.id_hotspot,
        id_tipo: self.id_tipo,
        id_layout: self.id_layout,
        id_vendedor: self.id_vendedor,
        cantidad: self.cantidad,
        longitud_pin: self.longitud_pin,
        tipo_pin: self.tipo_pin,
        tipo_autenticacion: self.tipo_autenticacion
      }

      let configuracion = Object.assign({precio: self.precio}, nuevoBloque)
      window.localStorage.setItem('argusblack.hotspot.generarVender.configuracion', JSON.stringify(configuracion))

      if(self.con_prefijo) {
        Object.assign(nuevoBloque,{
          prefijo_pin: self.prefijo_pin
        })
      }

      if(self.tipo_autenticacion == 2){
        Object.assign(nuevoBloque,{
          longitud_clave: self.longitud_clave,
          tipo_clave: self.tipo_clave
        })
      }

      bloques.push(nuevoBloque)

      var fichas = {
        bloques: bloques,
        cantidad: self.cantidad
      }

      FichasSrv.generar(fichas).then(response => {
        let resp = response.data
        let idFicha = resp[0]
        
        self.cargarFicha(idFicha)
        self.visible_formgenficha = false

        self.href = API + '/hotspot/fichas/' + idFicha + '/imprimir?_tk=' + localStorage.getItem('argusblack.token')

      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se generaron las fichas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    cargarFicha: function(idFicha){
      var self = this

      self.ficha = {}

      FichasSrv.fichaJSON(idFicha).then(response => {
        self.ficha = response.data

        var hotspot = self.hotspots.filter(hotspot => hotspot.id == self.id_hotspot)
        var idLan = hotspot[0].id_lan
    
        self.cargarLan(idLan)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las fichas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarLan:function(idLan){
      var self = this
      LanRouterSrv.lanJSON(idLan).then(response => {
        Object.assign(self.lan, response.data)
        self.generarQR()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo generar el codigo QR ni el PIN'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    generarQR (){
      var self = this

      var ip = self.lan.direccion_ip.split("/",1);
      
      var url = `http://${ip}/login?username=${self.ficha.pin}&password=${self.ficha.clave != null ? self.ficha.clave : ''}`

      self.codigo = url
      self.pin = self.ficha.pin
      self.clave = self.ficha.clave

      iu.msg.success('Se genero correctamente el codigo QR y el PIN')

      self.visible_qr = true

      self.conteo_generador++
    },

    imprimir() {
			let self = this
      let href = self.href + '&autoimprimir=true&con_botones=true'
			let a = window.open(href, 'Ficha', 'height=250, width=400');
    },

    obtenerPrecio: function () {
      var self = this

      if(self.id_tipo == null) {
        self.precio = 0
        return
      }

      var tipo = self.tipos.filter(tipo => tipo.id == self.id_tipo)
      self.precio = tipo[0].precio
    },

    obtenerTipoAutenticacion: function () {
      var self = this

      self.tipo_autenticacion = 1

      var index = self.layouts.findIndex(function (layout) {
        return layout.id == self.id_layout
      })

      if(index == -1) return
      
      self.tipo_autenticacion = self.layouts[index].tipo_autenticacion
    },

    verificarDatosCorrectos(){
      var self = this

      if(self.id_vendedor == null) {
        iu.msg.warning('Es necesario seleccionar un vendedor')
        self.$refs.vendedor.focus()
        self.bandera_spinner = false
        return false
      }

      if(self.id_hotspot == null) {
        iu.msg.warning('Es necesario seleccionar un hotspot')
        self.$refs.hotspot.focus()
        self.bandera_spinner = false
        return false
      }

      if(self.id_tipo == null){
        iu.msg.warning('Es necesario seleccionar un tipo de ficha')
        self.$refs.tipo_ficha.focus()
        self.bandera_spinner = false
        return false
      }

      if(self.id_layout == null) {
        iu.msg.warning('Es necesario registrar un layout de ficha')
        self.$refs.layout.focus()
        self.bandera_spinner = false
        return false
      }

      if(self.con_prefijo && self.prefijo_pin == ''){
        iu.msg.warning('Es necesario registar un prefijo')
        self.$refs.prefijo.focus()
        self.bandera_spinner = false
        return false
      }

      return true
    }
  }
}