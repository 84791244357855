<template>
  <Layout tituloPagina="Hotspot | Generar y vender">
    <div class="row">
      <div class="col-lg-5" v-show="visible_formgenficha">
        <div class="card">
          <h5 class="card-header">
            Generar Ficha
          </h5>
          <div class="card-body">
            <div v-show="usuarioActEsAdmin">
              <label>Vendedor</label>
              <div class="input-group">
                <select
                  name="vendedor"
                  ref="vendedor"
                  v-model="id_vendedor"
                  class="form-select"
                >
                  <option :value="null">Seleccionar</option>
                  <option
                    v-for="vendedor in vendedores"
                    :key="vendedor.id"
                    :value="vendedor.id"
                  >
                    {{vendedor.nombre}}
                    {{vendedor.apellido_paterno ? vendedor.apellido_paterno : ''}}
                    {{vendedor.apellido_materno ? vendedor.apellido_materno : ''}}
                  </option>
                </select>
                <router-link
                  :to="{ name: 'nuevoVendedorHotspot' }"
                  class="btn btn-success"
                  title="Nuevo vendedor"
                >
                  <i class="mdi mdi-plus-thick"></i>
                </router-link>
              </div>
            </div>
            <br v-show="usuarioActEsAdmin">
            <label>Hotspot</label>
            <div class="input-group">
              <select
                name="hotspot"
                ref="hotspot"
                v-model="id_hotspot"
                class="form-select"
                @change="cargarLayouts()"
              >
                <option :value="null">Seleccionar</option>
                <option
                  v-for="hotspot in hotspots"
                  :key="hotspot.id"
                  :value="hotspot.id"
                >
                  {{hotspot.nombre}}
                </option>
              </select>
              <router-link
                :to="{ name: 'nuevoHotspot' }"
                class="btn btn-success"
                title="Nuevo hotspot"
              >
                <i class="mdi mdi-plus-thick"></i>
              </router-link>
            </div>
            <br>
            <label>Tipo de ficha</label>
            <div class="input-group">
              <select
                name="tipo_ficha"
                ref="tipo_ficha"
                v-model="id_tipo"
                class="form-select"
                @change="
                  obtenerPrecio()
                "
              >
                <option :value="null">Seleccionar</option>
                <option
                  v-for="tipo in tipos"
                  :key="tipo.id"
                  :value="tipo.id"
                >
                  {{tipo.descripcion}}
                </option>
              </select>
              <router-link
                :to="{ name: 'nuevoFichaTipoHotspot' }"
                class="btn btn-success"
                title="Nuevo tipo de ficha"
              >
                <i class="mdi mdi-plus-thick"></i>
              </router-link>
            </div>
            <br>
            <div class="row">
              <div class="col-sm-6">
                <label>Layout de ficha</label>
                <div class="input-group">
                  <select
                    name="layout"
                    ref="layout"
                    v-model="id_layout"
                    class="form-select"
                    @change="obtenerTipoAutenticacion()"
                    :disabled="id_hotspot == null"
                    :readonly="id_hotspot == null"
                  >
                    <option :value="null">Seleccionar</option>
                    <option
                      v-for="layout in layouts"
                      :key="layout.id"
                      :value="layout.id"
                    >
                      {{layout.nombre}}
                    </option>
                  </select>
                  <router-link
                    :to="{ name: 'nuevoFichasLayoutHotspot' }"
                    class="btn btn-success"
                    title="Nuevo layout de ficha"
                  >
                    <i class="mdi mdi-plus-thick"></i>
                  </router-link>
                </div>
              </div>
              <div class="col-sm-6">
                <label for="autenticacion">Tipo de autenticación</label>
                <input
                  type="text"
                  class="form-control fw-semibold"
                  id="autenticacion"
                  :value="tipo_autenticacion == 1 ? 'PIN' : 'Usuario y contraseña'"
                  readonly
                  disabled
                  :class="{
                    'text-warning' : tipo_autenticacion == 2,
                    'text-success' : tipo_autenticacion == 1
                  }"
                />
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-sm-6">
                <label for="logitudPin">Longitud del PIN</label>
                <select id="logitudPin" v-model="longitud_pin" class="form-select">
                  <option v-for="(num,index) in 10" :key="`logitud-pin-${index}`" :value="num">{{num}}</option>
                </select>
              </div>
              <div class="col-sm-6">
                <label for="tipoPin">Tipo de PIN</label>
                <select id="tipoPin" v-model="tipo_pin" class="form-select">
                  <option value="alfanumerico">Alfanumérico</option>
                  <option value="numerico">Numérico</option>
                </select>
              </div>
            </div>
            <br>
            <div class="row" v-if="tipo_autenticacion == 2">
              <div class="col-md-6">
                <label for="logitudClave">Longitud de la clave</label>
                <select id="logitudClave" v-model="longitud_clave" class="form-select">
                  <option v-for="(num,index) in 10" :key="`logitud-clave-${index}`" :value="num">{{num}}</option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="tipoClave">Tipo de clave</label>
                <select id="tipoClave" v-model="tipo_clave" class="form-select">
                  <option value="alfanumerico">Alfanumérico</option>
                  <option value="numerico">Numérico</option>
                </select>
              </div>
            </div>
            <br v-if="tipo_autenticacion == 2">
            <div class="row">
              <div :class="con_prefijo ? 'col-md-6' : 'col-md-12'">
                <label for="">Con Prefijo de PIN</label>
                <div class="form-check form-switch form-switch-lg">
                  <input class="form-check-input" type="checkbox" id="conPrefijo" v-model="con_prefijo">
                  <label class="form-check-label" for="conPrefijo"></label>
                </div>
              </div>
              <div class="col-md-6" v-show="con_prefijo">
                <label for="prefijoPin">Prefijo del PIN</label>
                <input
                  type="text"
                  class="form-control"
                  id="prefijoPin"
                  v-model="prefijo_pin"
                  placeholder="PL1"
                  maxlength="3"
                  name="prefijo"
                  ref="prefijo"
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label for="precio">Precio</label>
                <div class="input-group">
                  <span class="input-group-text">
                    {{ monedaSistema.simbolo }}
                  </span>
                  <input
                    type="number"
                    class="form-control"
                    v-model="precio"
                    readonly disabled
                  />
                  <span class="input-group-text">
                    {{ monedaSistema.codigo }}
                  </span>
                </div>
              </div>
              <div class="col-md-6" style="padding-top: 23px;">
                <div class="d-grid gap-2">
                  <button
                    class="btn btn-success"
                    @click="generarFicha()"
                    :disabled="bandera_spinner"
                  >
                    <i 
                      class="mdi"
                      :class="!bandera_spinner ? 'mdi-cog' : 'mdi-concourse-ci mdi-spin'"
                    ></i>
                    Generar y vender
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3" v-show="visible_qr">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Ficha</h4>
            <div>
              <a href="#" onclick="return false;" @click="cerrarFichaGenerada()">
                <i class="mdi mdi-close text-dark"></i>
              </a>
            </div>
          </div>
          <div class="card-body text-center">
            <div class="text-center">
              <iframe id="ficha" :src="href" frameborder="0"
                style="width: 100%; height: 450px; position:relative;"></iframe>
            </div>
          </div>
        </div>
        <div class="text-right">
          <div class="btn-group">
            <button @click="cerrarFichaGenerada()" class="btn btn-secondary">
              <i class="mdi mdi-chevron-left"></i>
              Cerrar 
            </button>
            <button class="btn btn-primary" @click="imprimir()">
              <i class="mdi mdi-printer"></i>
              Imprimir
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script src="./generarVender.js"></script>

<style scoped>
@media print {
  .desaparecer {
    display: none
  }
} 
@page {
  size: auto;
  margin: 0;
}
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>